import React, { useState } from "react";
import Faq from "react-faq-component";
import { Checkbox } from "primereact/checkbox";
import { Link } from "react-router-dom";
import "./style.css";
import ReadMore from "../ReadMore/ReadMore";

function FAQ({ pageData, lang }) {
  const [checked, setChecked] = useState(false);

  const sectionOne = {
    title: pageData.questionSections.section1.title,
    rows: [
      {
        title: pageData.questionSections.section1.rows[0].title,
        content: pageData.questionSections.section1.rows[0].content,
      },
      {
        title: pageData.questionSections.section1.rows[1].title,
        content: (
          <p> {pageData.questionSections.section1.rows[1].content}
            <Link aria-label="medicines" to="/medicines">
              <span class="bold-span">{lang === 'english' ? 'here' : 'aquí'}</span>
            </Link>
            .
          </p>
        ),
      },
    ],
    styles: {
      titleTextColor: "#3E000C",
      titleTextSize: "25px",
    },
  };
  const sectionTwo = {
    title: pageData.questionSections.section2.title,
    rows: [
      {
        title: pageData.questionSections.section2.rows[0].title,
        content: (
          <div>
            <p>
              {pageData.questionSections.section2.rows[0].content.header}
            </p>
            <ul className="custom-list">
              {pageData.questionSections.section2.rows[0].content.list.map((item, index) => {
                if (index === 3) {
                  return lang === 'english' ?
                    <li>You do not have insurance coverage for a{" "}
                      <Link to="/medicines">
                        <span class="bold-span">medicine listed on this site</span>
                      </Link>
                      , and
                    </li>
                    :
                    <li>No tiene cobertura de seguro para un{" "}
                      <Link to="/medicines">
                        <span class="bold-span">medicamento enumerado en este sitio </span>
                      </Link>
                    </li>
                } else {
                  return <li>{item}</li>
                }
              })}

            </ul>
            <br />
            <p>
              <span dangerouslySetInnerHTML={{ __html: pageData.questionSections.section2.rows[0].content.footer }}></span>
              {/* <Link to="/eligibility-assessment">
                <span class="bold-span">{lang === 'english' ? 'assessment' : 'evaluación'}</span>
              </Link> */}
              .
            </p>
            <br />
            <p>
              <span dangerouslySetInnerHTML={{ __html: pageData.questionSections.section2.rows[0].content.footnote }}></span>
            </p>
          </div>
        ),
      },
      {
        title: pageData.questionSections.section2.rows[1].title,
        content: (
          <div>
            {lang === 'english' ?
              <p>To be eligible for BMSPAF you need to live in the United States or a U.S.territory.<br /> Please contact BMSPAF at <span style={{ whiteSpace: "nowrap" }}><a class="bold-span" href="tel:+1-800-736-0003">1-800-736-0003</a></span> for more information.</p>
              :
              <p>Para ser elegible para BMSPAF, debe vivir en Estados Unidos o en un territorio de EE. UU.<br /> Comuníquese con BMSPAF al <span style={{ whiteSpace: "nowrap" }}><a class="bold-span" href="tel:+1-800-736-0003">1-800-736-0003</a></span> para obtener más información.</p>
            }
          </div>
        )
      },
      {
        title: pageData.questionSections.section2.rows[2].title,
        content: (
          <div>
            <ul className="plain-list">
              {pageData.questionSections.section2.rows[2].content.list.map((item, index) => {
                return <li>{item}</li>
              })}
            </ul>
          </div>
        ),
      },
      {
        title: pageData.questionSections.section2.rows[3].title,
        content: (
          <div>
            {lang === 'english' ?
              <p>Visit Medicare.gov or call <span style={{ whiteSpace: "nowrap" }}><a class="bold-span" href="tel:+1-800-633-4227">1-800-MEDICARE (1-800-633-4227)</a></span> to see if you qualify for LIS/Extra Help.</p>
              :
              <p>Visite Medicare.gov o llame al <span style={{ whiteSpace: "nowrap" }}><a class="bold-span" href="tel:+1-800-633-4227">1-800-MEDICARE (1-800-633-4227)</a></span> para ver si califica para LIS/Ayuda adicional.</p>
            }
          </div>
        ),
      },
      {
        title: pageData.questionSections.section2.rows[4].title,
        content: pageData.questionSections.section2.rows[4].content
      },
    ],
  };
  const sectionThree = {
    title: pageData.questionSections.section3.title,
    rows: [
      {
        title: pageData.questionSections.section3.rows[0].title,
        content: (
          <p>
            {pageData.questionSections.section3.rows[0].content.part1}
            <Link aria-label="apply" to="/apply">
              <span class="bold-span">{lang === 'english' ? 'here' : 'aquí'}</span>
            </Link>
            <span dangerouslySetInnerHTML={{ __html: pageData.questionSections.section3.rows[0].content.part2 }}></span>
          </p>
        ),
      },
      {
        title: pageData.questionSections.section3.rows[1].title,
        content: (
          <p>
            {pageData.questionSections.section3.rows[1].content}
            <Link aria-label="about" to="/about">
              <span class="bold-span">{lang === 'english' ? 'here' : 'aquí'}</span>
            </Link>
            .
          </p>
        ),
      },
      {
        title: pageData.questionSections.section3.rows[2].title,
        content: (
          <div dangerouslySetInnerHTML={{ __html: pageData.questionSections.section3.rows[2].content }}></div>
        ),
      },
      {
        title: pageData.questionSections.section3.rows[3].title,
        content: pageData.questionSections.section3.rows[3].content
      },
      {
        title: pageData.questionSections.section3.rows[4].title,
        content: pageData.questionSections.section3.rows[4].content
      },
      {
        title: pageData.questionSections.section3.rows[5].title,
        content: (
          <div>
            <ul className="custom-list">
              {pageData.questionSections.section3.rows[5].content.map(item => (
                <li>{item}</li>
              ))}
            </ul>
          </div>
        ),
      },
      {
        title: pageData.questionSections.section3.rows[6].title,
        content: pageData.questionSections.section3.rows[6].content
      },
      {
        title: pageData.questionSections.section3.rows[7].title,
        content: (
          <div dangerouslySetInnerHTML={{ __html: pageData.questionSections.section3.rows[7].content }}></div>
        ),
      },
      {
        title: pageData.questionSections.section3.rows[8].title,
        content: (
          <div dangerouslySetInnerHTML={{ __html: pageData.questionSections.section3.rows[8].content }}></div>
        ),
      },
    ],
  };
  const sectionFour = {
    title: pageData.questionSections.section4.title,
    rows: [
      {
        title: pageData.questionSections.section4.rows[0].title,
        content: (
          <div>
            <ul className="custom-list">
              {pageData.questionSections.section4.rows[0].content.map(item => (
                <li dangerouslySetInnerHTML={{ __html: item }}></li>
              ))}
            </ul>
          </div>
        ),
      },
      {
        title: pageData.questionSections.section4.rows[1].title,
        content: (
          <p dangerouslySetInnerHTML={{ __html: pageData.questionSections.section4.rows[1].content }}></p>
        ),
      },
    ],
  };

  const config = {
    arrowIcon: " ",
    tabFocus: true
  };

  const toggleExpansion = (e) => {
    let rows = document.getElementsByClassName("faq-row");
    if (e.checked) {
      expandAll(rows);
    } else {
      collapseAll(rows);
    }
    setChecked(e.checked);
  };

  const collapseAll = (rows) => {
    for (let i = 0; i < rows.length; i++) {
      let rowTitle = rows[i].getElementsByClassName("row-title")[0];
      rowTitle.classList.remove("expanded", "styles_expanded__3elPy");
      rowTitle.classList.add("closed", "styles_closed__39w54");
      rowTitle.setAttribute("aria-expanded", "false");
      let rowContent = rows[i].getElementsByClassName("row-content")[0];
      rowContent.setAttribute("aria-expanded", "false");
      rowContent.setAttribute("aria-hidden", "true");
      rowContent.setAttribute("style", "height: 0px;");
    }
  };

  const expandAll = (rows) => {
    for (let i = 0; i < rows.length; i++) {
      let rowTitle = rows[i].getElementsByClassName("row-title")[0];
      rowTitle.classList.add("expanded", "styles_expanded__3elPy");
      rowTitle.classList.remove("closed", "styles_closed__39w54");
      rowTitle.setAttribute("aria-expanded", "true");
      let rowContent = rows[i].getElementsByClassName("row-content")[0];
      rowContent.setAttribute("aria-expanded", "true");
      rowContent.setAttribute("aria-hidden", "false");
      rowContent.setAttribute(
        "style",
        "height: " + rowContent.scrollHeight + "px;"
      );
    }
  };

  const backToTop = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  };

  return (
    <main id="main">
      <div className="font-face-p">
        <div className="container-fluid">
          <div className="row g-0">
            <div className="faq-header col-sm-12 mx-auto d-flex justify-content-center align-items-center">
              <div className="overlay"></div>
              <div className="banner-text-div mx-auto">
                <h1 className="banner-text">{pageData.title}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row g-0">
            <div className="checkbox col-sm-12 col-md-8 mx-auto">
              <div className="p-field-checkbox">
                <Checkbox
                  role="checkbox"
                  aria-label="Expand all questions"
                  aria-checked={checked}
                  className="expand-all-box"
                  inputId="binary"
                  checked={checked}
                  onChange={(e) => toggleExpansion(e)}
                  onKey
                />
                <label className="expand-all">{pageData.toggleLabel}</label>
              </div>
            </div>
          </div>
          <div className="row g-0">
            <div className="faq-content col-sm-12 col-md-8 mx-auto">
              <Faq id="r1" data={sectionOne} config={config} />
              <Faq id="r2" data={sectionTwo} config={config} />
              <Faq id="r3" data={sectionThree} config={config} />
              <Faq id="r4" data={sectionFour} config={config} />
            </div>
          </div>
          <div className="faq-back-to-top row g-0">
            <div className="col-md-8 col-sm-12 mx-auto">
              <Link
                tabIndex={0}
                style={{
                  marginTop: "1em",
                  color: "black",
                  "border-bottom": "2px solid #EB7100",
                  textDecoration: "none",
                }}
                onClick={(e) => backToTop(e)}
              >
                <span class="bold-span">{pageData.backBtnLabel}</span>
              </Link>
            </div>
          </div>
        </div>
        <ReadMore content={pageData.readMore} />
      </div>
    </main>
  );
}

export default FAQ;
